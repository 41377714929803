<template>
  <div id="print" class="main">
    <v-container>
      <v-row>
        <v-container>
          <h2>รายการบิล</h2>
        </v-container>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="dataTable"
            :items-per-page="10"
            class="elevation-1"
            :search="searchTxt"
            ><template v-slot:top>
              <v-row class="mx-2" no-gutters>
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Order Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable @change="loadData(date)">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="searchTxt"
                    append-icon="mdi-magnify"
                    label="ค้นหา"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.LineTotal="{ item }">
              <span>{{ FormatNumber(item.LineTotal) }}</span>
            </template>
            <template v-slot:item.AmountQtyTotal="{ item }">
              <span>{{ FormatNumber(item.AmountQtyTotal) }}</span>
            </template>
            <template v-slot:item.DiscountTotal="{ item }">
              <span>{{ FormatNumber(item.DiscountTotal) }}</span>
            </template>
            <template v-slot:item.Total="{ item }">
              <span>{{ FormatNumber(item.Total) }}</span>
            </template>
            <template v-slot:item.Actions="{ item }">
              <v-icon class="mr-3 primary--text" @click="editLine(item)">
                mdi-cog-transfer-outline
              </v-icon>
            </template>
            <template v-slot:item.Status="{ item }">
              <span v-if="item.Status == 0">ยกเลิก</span>
              <span v-if="item.Status == 1">ใช้อยู่</span>
              <span v-if="item.Status == 2">จ่ายแล้ว</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "OrderTable",
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      dataTable: [],
      searchTxt: "",
      headers: [
        {
          text: "เลขที่เอกสาร",
          value: "OrderId",
          width: 130,
        },
        {
          text: "วันที่",
          value: "OrderDateStr",
          width: 110,
        },
        {
          text: "เวลา",
          value: "OrderTime",
          width: 60,
        },
        {
          text: "จำนวนคน",
          value: "QtyTotal",
          width: 90,
          align: "right",
        },
        {
          text: "ยอดอื่น",
          value: "LineTotal",
          width: 80,
          align: "right",
        },
        {
          text: "ยอดคน",
          value: "AmountQtyTotal",
          width: 80,
          align: "right",
        },
        {
          text: "ส่วนลด",
          value: "DiscountTotal",
          width: 80,
          align: "right",
        },
        {
          text: "ยอดรวม",
          value: "Total",
          width: 80,
          align: "right",
        },
        {
          text: "แก้ไขโดย",
          value: "ModifiedBy",
          width: 80,
        },
        {
          text: "เวลาแก้ไข",
          value: "ModifiedDateStr",
          width: 130,
        },
        {
          text: "สถานะ",
          value: "Status",
          width: 80,
        },
        {
          text: "",
          value: "Actions",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions("transactions", [
      "actOrderTableGets",
      "actOrderTableUpdateUse",
    ]),
    loadData() {
      let item = { OrderDate: this.date };
      console.log(this.date);

      this.actOrderTableGets(item).then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.dataTable = data.result;
        } else {
          this.Error(data.message);
        }
      });
    },
    editLine(item) {
      console.log(item);
      item.Status = 1;
      item.ModifiedBy = this.user.UserId;
      console.log(item);
      this.actOrderTableUpdateUse(item);
    },
  },
};
</script>

<style>
.container {
  max-width: 1500px;
}
</style>
